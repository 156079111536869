
import { defineComponent, ref, onMounted, computed } from "vue";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import UserService from "@/core/services/UserService";
// import InvoiceAction from "@/components/dropdown/car/InvoiceAction.vue";
import { roles as role } from "@/store/stateless/store";

interface UserBodyParam {
  per_page: string;
  agent: string;
  role_id: number;
  keyword: string;
  start_date: string;
  end_date: string;
  status: any;
}

export default defineComponent({
  name: "users-list",
  components: {},
  data() {
    return {
      data: [],
      shortcuts: [
        {
          text: "Last week",
          value: (() => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            return [start, end];
          })(),
        },
        {
          text: "Last month",
          value: (() => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            return [start, end];
          })(),
        },
        {
          text: "Last 3 months",
          value: (() => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            return [start, end];
          })(),
        },
      ],
      value1: "",
      value2: "",
      invoiceStatus: ["-", "Pending", "Completed", "Failed"],
    };
  },
  setup() {
    // let customers_obj = ref([]);
    let responseResult = ref<any>({});
    let userStatus = ref<any>("");
    let roles = ref<any>("");
    responseResult.value.success = false;
    responseResult.value.failed = false;
    responseResult.value.execute = false;
    const store = useStore();
    let bodyParameters = {} as UserBodyParam;
    const checkedCustomers = ref([]);
    let sales_agent_id: any;

    bodyParameters = {
      per_page: "50",
      agent: "all",
      role_id: 0,
      keyword: "",
      start_date: "",
      end_date: "",
      status: "",
    };

    onMounted(() => {
      if(!role.users.includes(role.userId())) window.location.href = '/#/404'
      
      
      MenuComponent.reinitialization();
      UserService.getRoles();

      setCurrentPageBreadcrumbs("Users Listing", ["Users"]);
      // API Action to get user list
      bodyParameters.keyword = "";
      getUserList(bodyParameters);

      // Promise.all([MasterService.getTaskReasons({ task_status: event })]).then(
      //   (data) => {
      //     // taskReason.value.value = null;
      //     // taskReason.value.options = [];
      //     // // taskReason.value.options = store.getters.getUserTaskReasons?.statuses;
      //   }
      // );
    });

    const search = ref<string>("");
    const searchUser = () => {
      bodyParameters.keyword = search.value;
      getUserList(bodyParameters);
    };

    const searchUserByRole = (event) => {
      bodyParameters.role_id = event.target.value;
      getUserList(bodyParameters);
    };
    const searchUserByStatus = (event) => {
      bodyParameters.status = event.target.value;
      getUserList(bodyParameters);
    };

    const getUserList = (bodyParameters) => {
      currentPage.value = 1;
      UserService.getUserList(bodyParameters,currentPage.value);
    };

    const getPaginationUrl = (index) => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
      bodyParameters.keyword = "";
      UserService.getUserList(bodyParameters,currentPage.value);
    
    };
    

    const showAction = () => {
      //
    };
    // let userTaskReason = computed(() => {
    //   return store.getters.getUserTaskReasons;
    // });

    // const userTask = ref({
    //   placeholder: "Please Select Response",
    //   value: null,
    //   options: userTaskList.value.statuses,
    //   searchable: true,
    //   clearOnSelect: true,
    // });

    const resetBodyParam = () => {
      bodyParameters = {
        per_page: "50",
        agent: "all",
        role_id: 0,
        keyword: "",
        start_date: "",
        end_date: "",
        status: "",
      };
      userStatus.value = "";
      roles.value = "";
      search.value = "";
      UserService.getUserList(bodyParameters);
    };

    const paginatioObject = computed(() => {
      return store.getters.getPaginationObj;
    });

    const onPerPageChange = (event) => {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      bodyParameters.per_page = event.target.value;
      getUserList(bodyParameters);
    }

    const currentPage = ref(1);
    return {
      onPerPageChange,
      getPaginationUrl,
      currentPage,
      roles,
      role,
      showAction,
      search,
      searchUser,
      bodyParameters,
      responseResult,
      searchUserByRole,
      userStatus,
      searchUserByStatus,
      resetBodyParam,
      paginatioObject,
    };
  },
});
